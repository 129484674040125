import {graphql, withPrefix} from 'gatsby';
import Img from 'gatsby-image';
import React from 'react';

import {CaseHeader} from '../../../components/case_header';
import {WillAnimate} from '../../../components/content_animate';
import {Cta} from '../../../components/cta';
import {Layout} from '../../../components/layout';
import {ScrollTo} from '../../../components/scroll_to';

const translatedPagePath = '/en/portfolio/saas-product-keeping/';

export default ({data}: any) => (
    <Layout
        title="Case: Ons eigen product: Keeping – Supereenvoudige tijdregistratie – Voorbeeld van ons werk"
        description="We ontwikkelen ons eigen SaaS-product om gewerkte uren bij te houden. Lees alles over ons uitgebreide tijdregistratieproduct Keeping."
        ogImage="keeping.png"
        translatedPagePath={translatedPagePath}
        navColor="white">
        <CaseHeader
            urlBack="/nl/portfolio/taxatieplatform-taxapi/"
            titleBack="Case: Modern vastgoed taxeren: Taxatieplatform TaXapi"
            urlForward="/nl/portfolio/vertaalplatform-fairlingo/"
            titleForward="Case: Vertaalplatform Fairlingo"
            translatedPagePath={translatedPagePath}>
            <Img className="hero__image" alt="Keeping" fluid={data.keeping.childImageSharp.fluid} critical />

            <div className="hero__content-wrapper">
                <div className="hero__content">
                    <h1>
                        {/* Make sure to have the correct spacing when br becomes invisible */}
                        Breng orde <br /> in jouw tijd
                    </h1>
                    <h2>Eigen SaaS-product: Keeping</h2>
                </div>
            </div>
            <ScrollTo className="hero__scroll" target="/nl/portfolio/saas-product-keeping/#from-hero" />
        </CaseHeader>

        <section className="section">
            {/* Case intro */}
            <article className="content">
                <div className="container">
                    <div className="content__display content__display--intro">
                        <h2>
                            Om alle uren die we voor onze projecten draaien nauwkeurig bij te houden, ont&shy;wikkelen{' '}
                            we ons eigen <span className="highlight">SaaS-product</span>. In eenvoud elegant, in kunnen{' '}
                            krachtig.
                        </h2>
                    </div>
                </div>
            </article>

            {/* Case assignment */}
            <WillAnimate as="article" id="from-hero" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>Het idee</h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Binnen Label305 hebben we altijd te maken gehad met urenregistratie. Als software engineers{' '}
                            en -consultants is het voor ons belangrijk om elke minuut van gewerkte tijd nauwkeurig te{' '}
                            registreren. Zo houden we grip op onze projecten.
                        </p>
                        <p>
                            Na het gebruiken van verschillende tijdregistratie&shy;systemen besloten we om een eigen{' '}
                            invulling te geven aan urenregistratie. We zetten onze expertise in om een{' '}
                            <span className="highlight">SaaS-product</span> te ontwikkelen voor zzp'ers en het MKB. Een{' '}
                            product die het mogelijk maakt om teams, projecten, taken en vooral tijd op een{' '}
                            <span className="highlight">gebruiksvriendelijke</span> manier te managen.
                        </p>
                        <p>
                            Het doel is dat iedere ondernemer die zich voor{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/"
                                title="Keeping: SaaS-product voor urenregistratie"
                                target="_blank">
                                Keeping
                            </a>{' '}
                            aanmeldt een productiviteits&shy;verbetering kan realiseren door meer inzicht te krijgen in{' '}
                            de tijdsbesteding. De manier om dit te doen is door een platform te ontwikkelen dat simpel{' '}
                            maar ook goed werkt waardoor het echt een integraal onderdeel kan vormen in een bedrijf.
                        </p>
                        <ul className="list">
                            <li className="ion-md-arrow-dropright">Product management</li>
                            <li className="ion-md-arrow-dropright">UX- en UI-ontwerp</li>
                            <li className="ion-md-arrow-dropright">SaaS-productontwikkeling</li>
                            <li className="ion-md-arrow-dropright">Chrome-extensie en Firefox add-on</li>
                            <li className="ion-md-arrow-dropright">Unieke integraties met externe diensten</li>
                        </ul>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img alt="De mobiele applicaties van Keeping" fluid={data.keeping3d.childImageSharp.fluid} />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            Een duidelijke interface. Simpele dingen blijven simpel, dieperliggende functionaliteit is
                            gemakkelijk beschikbaar.
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Case challenge */}
            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>Uitdaging</h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Een SaaS-product ontwikkelen waar iedere ondernemer mee overweg kan vereist een precieze{' '}
                            balans tussen UI, UX en techniek. Hiervoor moeten personen met uiteenlopende expertises nauw{' '}
                            samenwerken.
                        </p>
                        <p>
                            De interface moet simpel zijn zodat tijdregistratie vlot en{' '}
                            <span className="highlight">intuïtief</span> blijft, tegelijkertijd moeten voldoende{' '}
                            functies beschikbaar zijn om betekenis te geven aan de uren in de interface.
                        </p>
                        <p>
                            Om een brede inzet van het product te garanderen is beschikbaarheid op{' '}
                            <span className="highlight">alle grote platformen</span> essentieel. Hierbij mag echter geen{' '}
                            kwaliteit verloren gaan ten behoeve van beschikbaarheid.
                        </p>
                        <p>
                            Geschikt zijn voor zowel zzp'ers als het MKB betekent dat Keeping{' '}
                            <span className="highlight">schaalbaar</span> ontwikkeld moet worden. Een zzp'er, een team{' '}
                            van 4 personen en een team van 500 personen moeten allen op dezelfde manier gebruik kunnen{' '}
                            blijven maken van urenregistratie <span className="highlight">zonder concessies</span>.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="div" className="content">
                <div className="video">
                    <div className="video__content">
                        <div className="video__browser">
                            <img alt="Browser graphic" src={withPrefix('/images/chrome_browser.svg')} />
                            <small>https://www.ellas-evenementen.keeping.nl/</small>
                            <video
                                width="100%"
                                playsinline
                                loop
                                muted
                                autoPlay
                                poster={withPrefix(
                                    '/images/keeping-urenregistratie-een-nieuwe-tijdregistratie-starten-placeholder.jpg',
                                )}>
                                <source
                                    src={withPrefix(
                                        '/video/keeping-urenregistratie-een-nieuwe-tijdregistratie-starten.mp4',
                                    )}
                                    type="video/mp4"
                                />
                            </video>
                        </div>
                    </div>
                    <div className="video__caption">
                        <span className="ion-md-arrow-dropright">
                            Eenvoudig een tijdregistratie starten in de webapplicatie van{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/ontdek-urenregistratie"
                                title="Keeping time tracking (Dutch)"
                                target="_blank">
                                Keeping
                            </a>
                            .
                        </span>
                    </div>
                </div>
            </WillAnimate>

            {/* Article idea and challenges description */}

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            We maken <br /> software <br /> die schaalt
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Voor ons eigen product, Keeping, doen we alles zelf. Er is geen externe opdrachtgever. We{' '}
                            hebben zelf de rol van bedenker, productmanager, ontwerper, ontwikkelaar en ondersteuner.{' '}
                        </p>
                        <p>
                            Door dezelfde <span className="highlight">agile werkwijze</span> aan te houden als die voor{' '}
                            onze opdrachtgevers, realiseren we een gestroomlijnde ontwikkeling met tijdige opleveringen.{' '}
                            Scrum en sprints vormen de basis van alle ontwikkeliteraties voor Keeping.
                        </p>
                        <p>
                            Tijdens de ontwikkeling focussen we ons <span className="highlight">parallel</span> op{' '}
                            verschillende onderdelen van het softwareproduct. Zo wordt parallel gewerkt aan de{' '}
                            browser-extensie, maar ook aan een verbeterde versie van de Android&shy;applicatie. Dit
                            zorgt voor <span className="highlight">subprojecten</span> binnen het hoofdproject Keeping,
                            elk met eigen iteraties.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image">
                                <div className="image__content">
                                    <Img
                                        alt="Krijg inzicht met gedetailleerde rapportages"
                                        fluid={data.keepingReports.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="content__copy">
                            <h2>Grip op tijd en teams</h2>
                            <p>
                                Om overzichtelijk te maken welke uren door wie worden gedraaid houdt elke gebruiker uren{' '}
                                apart bij. Jouw{' '}
                                <a
                                    className="link"
                                    rel="follow"
                                    href="https://keeping.nl/ontdek-urenregistratie"
                                    title="Keeping: tijd, teams en meer"
                                    target="_blank">
                                    Keeping-omgeving
                                </a>{' '}
                                is <span className="highlight">instelbaar</span> zodat er naar wens gebruik gemaakt kan
                                worden van projecten, klanten en taken.
                            </p>
                            <p>
                                Er ontstaan overzichten van uren per gebruiker, per project, klant en taak. Dat zorgt
                                voor waardevolle informatie waar facturen en toekomstige ureninschattingen op gebaseerd
                                kunnen worden.
                            </p>
                            <p>
                                Onze UX- en UI-engineers hebben een <span className="highlight">fijne interface</span>{' '}
                                opgeleverd waar iedere ondernemer binnen de kortste keren bekend mee is.
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__image content__image--right">
                        <div className="image">
                            <div className="image__content">
                                <Img
                                    alt="De web-extensie van Keeping voor Chrome"
                                    fluid={data.keepingExtension.childImageSharp.fluid}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="content__copy">
                        <h2>Extensies en add-ons</h2>
                        <p>
                            Chrome en Firefox behoren tot de meestgebruikte browsers ter wereld. Het ontwikkelen van een
                            SaaS-product zoals Keeping gaf ons de uitgerekende mogelijkheid om onze expertise met deze
                            browsers in te zetten.
                        </p>
                        <p>
                            Om de gebruikservaring te verbeteren bedachten we een manier om een onderdeel van onze{' '}
                            tijdregistratiesysteem te <span className="highlight">integreren in de browser</span>. Door
                            deze{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/urenregistratie/google-chrome-extensie"
                                title="Keeping: de Google Chrome Extensie"
                                target="_blank">
                                Keeping browser-extensies
                            </a>{' '}
                            ontstaat een unieke ervaring voor de gebruiker, belangrijke handelingen worden gemakkelijker
                            uit te voeren en zijn sneller bereikbaar.
                        </p>
                        <p>
                            Een tijdregistratie starten kan vanaf elke willekeurige pagina in de browser, de gebruiker
                            hoeft niet meer naar de Keeping-website. Daarnaast wordt alles doorlopend gesynchroniseerd
                            met Keeping waardoor de tijdregistraties die je via de browser invoert direct te zien zijn
                            via elke toepassing.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content-group">
                        <div className="content-group__background content-group__background--right" />
                        <div className="content__image">
                            <div className="image">
                                <div className="image__content">
                                    <Img
                                        alt="De mobiele applicaties van Keeping"
                                        fluid={data.keepingAndroid.childImageSharp.fluid}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className="content__copy">
                            <h2>Apps voor een brede ervaring</h2>
                            <p>
                                Hedendaags is het bijna ondenkbaar dat een (SaaS-)product niet beschikt over online{' '}
                                mogelijkheden voor mobiel. Voor{' '}
                                <a
                                    className="link"
                                    rel="follow"
                                    href="https://keeping.nl/urenregistratie-app"
                                    title="Keeping: native mobiele applicaties voor urenregistratie"
                                    target="_blank">
                                    Keeping
                                </a>{' '}
                                ontwikkelen we twee <span className="highlight">native applicaties</span>, één voor
                                Android en één voor iOS.
                            </p>
                            <p>
                                Mobiele applicaties geven een product de kans om{' '}
                                <span className="highlight">dichtbij de gebruiker</span> te staan en op elk moment, waar
                                dan ook, beschikbaar te blijven voor gebruik. Zo worden de mobiele applicaties van
                                Keeping door ondernemers gebruikt tijdens hun werk, onderweg naar een opdracht of zelfs
                                op locatie in een ander land.
                            </p>

                            <p>
                                De flexibiliteit die de mobiele applicaties verzorgen is goud waard. Tenminste, dat
                                horen we van de gebruikers.
                            </p>

                            <p>
                                <em>
                                    Noot: Per 1 april 2021 biedt Label305 geen native app ontwikkeling meer aan, en
                                    wordt ontwikkeling aan Keeping's mobiele apps uitbesteed aan een andere Nederlandse
                                    partij.
                                </em>
                            </p>
                        </div>
                    </div>
                </div>
            </WillAnimate>

            {/* Article integrations  */}

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Integraties <br /> met <br /> Keeping
                        </h2>
                    </div>

                    <div className="content__copy">
                        <p className="p-lead">
                            Facturatie, projectmanagement en urenregistratie gaan hand in hand dankzij de{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/urenregistratie-integraties"
                                title="Keeping-integraties met externe tools"
                                target="_blank">
                                integraties
                            </a>{' '}
                            van Keeping.
                        </p>
                        <p>
                            Hou direct binnen je favoriete <span className="highlight">projectmanagementtools</span> je
                            uren bij. En factureer inspanning vanuit Keeping voor een haarscherp inzicht in het{' '}
                            <span className="highlight">financiele resultaat</span> van jouw werk.
                        </p>
                        <p>
                            De integraties met externe diensten staan in het kader van het verbreden van de{' '}
                            beschikbaarheid van ons product. Dit verzorgt een brede maar unieke gebruikservaring. Het
                            belangrijkste hierbij is het bewaken van de{' '}
                            <span className="highlight">consistente kwaliteit</span>, zo blijft ons SaaS-product prettig
                            in gebruik.
                        </p>
                        <p>
                            Zo is het zaak dat elke integratie op dezelfde gebruiksvriendelijke manier te gebruiken is.
                            Verwijzingen en uren gekoppeld aan externe diensten moeten daarnaast op dezelfde manier
                            consistent in Keeping verwerkt worden.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Asana" fluid={data.asana.childImageSharp.fluid} />
                                <span>Asana</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Basecamp" fluid={data.basecamp.childImageSharp.fluid} />
                                <span>Basecamp</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Exact online" fluid={data.exact.childImageSharp.fluid} />
                                <span>Exact online</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Atlassian Jira" fluid={data.jira.childImageSharp.fluid} />
                                <span>Atlassian Jira</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Moneybird" fluid={data.moneybird.childImageSharp.fluid} />
                                <span>Moneybird</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Nmbrs" fluid={data.nmbrs.childImageSharp.fluid} />
                                <span>Nmbrs</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Todoist" fluid={data.todoist.childImageSharp.fluid} />
                                <span>Todoist</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Trello" fluid={data.trello.childImageSharp.fluid} />
                                <span>Trello</span>
                            </div>
                        </div>
                    </div>
                    <div className="logos-grid-caption">
                        <span className="ion-md-arrow-dropright">
                            Een selectie van integraties met Keeping. Bekijk alle integraties op de{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/urenregistratie-integraties"
                                title="De website van Keeping"
                                target="_blank">
                                website
                            </a>
                            .
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display content__display--right">
                        <h2>Onder&shy;steuning, marketing & communicatie</h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Voor een Saas-product zoals Keeping is een duidelijke, vindbare website en goede support,
                            minstens zo belangrijk als de software zelf.
                        </p>
                        <p>
                            Naast de ontwikkeling van de verschillende applicaties van Keeping, hebben wij zelf de
                            gehele <span className="highlight">marketing</span>,{' '}
                            <span className="highlight">SEO geoptimaliseerde website</span> en{' '}
                            <span className="highlight">support team</span> opgezet.
                        </p>
                        <p>
                            Om Keeping gebruikers snel te kunnen helpen, is er op de website een uitgebreide{' '}
                            <span className="highlight">kennisbank</span> te vinden, met talrijke ondersteunende
                            artikelen.
                        </p>
                        <p>
                            Voor een positieve, eerste kennismaking met Keeping, hebben wij ervoor gekozen om een modern
                            vormgegeven, zakelijke website te combineren met kleurrijke en vriendelijke graphics die in
                            samenwerking met een illustrator zijn ontwikkeld.
                        </p>

                        <p>
                            Het resultaat: een duidelijk en sprekende website die ons product perfect presenteerd en
                            Keeping gebruikers snel op weg helpt.
                        </p>
                    </div>
                </div>
            </WillAnimate>

            {/* Article image and role  */}

            <WillAnimate as="div" className="content">
                <div className="image">
                    <div className="image__content">
                        <Img
                            alt="Team aan het werk op kantoor met Keeping"
                            fluid={data.keepingWebsite.childImageSharp.fluid}
                        />
                    </div>
                    <div className="image__caption">
                        <span className="ion-md-arrow-dropright">
                            Een duidelijke applicatie, verdient een goede, aantrekkelijke website. Een helder overzicht
                            van alle functionaliteiten en apps vind je op de{' '}
                            <a
                                className="link"
                                rel="follow"
                                href="https://keeping.nl/homepage"
                                title="De website van Keeping"
                                target="_blank">
                                Keeping-website
                            </a>
                            .
                        </span>
                    </div>
                </div>
            </WillAnimate>

            <WillAnimate as="article" className="content">
                <div className="container">
                    <div className="content__display">
                        <h2>
                            {/* Make sure to have the correct spacing when br becomes invisible */}
                            Techniek <br /> achter <br /> Keeping
                        </h2>
                    </div>
                    <div className="content__copy">
                        <p className="p-lead">
                            Het samenkomen van een breed scala aan technische expertises was noodzakelijk voor de{' '}
                            totstandkoming van Keeping.
                        </p>
                        <p>
                            De webapplicatie van Keeping is ontwikkeld op het solide fundament van het{' '}
                            <span className="highlight">Laravel-framework</span>. Daarnaast zijn belangrijke
                            veelgebruikte interface componenten zoals het 'Mijn uren'-overzicht gemaakt met{' '}
                            <span className="highlight">React</span> om een vloeiende interactieve ervaring mogelijk te{' '}
                            maken. Daarnaast biedt Keeping ook browser&shy;extensies voor Chrome en Firefox aan,{' '}
                            geschreven in TypeScript.
                        </p>
                        <p>
                            De mobiele apps voor Keeping zijn <span className="highlight">native</span> geschreven in{' '}
                            Kotlin en Swift, respectievelijk voor Android en iOS. Deze apps maken verbinding met de{' '}
                            webapplicatie via een <span className="highlight">API</span>, die ook te gebruiken is door{' '}
                            externe ontwikkelaars.
                        </p>
                        <p>
                            Tot slot draait Keeping op redundant uitgevoerde{' '}
                            <span className="highlight">cloud&shy;infrastructuur</span> geleverd door DigitalOcean. We{' '}
                            <span className="highlight">monitoren</span> continu de gezondheid van het softwareproduct{' '}
                            met externe tooling zoals: AppOptics, Bugsnag, Crashlytics en Pingdom. Als er een probleem{' '}
                            optreedt kunnen we direct ingrijpen.
                        </p>
                    </div>
                </div>

                <div className="container">
                    <div className="logos-grid logos-grid--tech">
                        <div className="logos-grid__background" />

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo Laravel" fluid={data.laravel.childImageSharp.fluid} />
                                <span>Laravel</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo PHP" fluid={data.php.childImageSharp.fluid} />
                                <span>PHP</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Mysql" fluid={data.mysql.childImageSharp.fluid} />
                                <span>MySQL</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Javascript" fluid={data.javascript.childImageSharp.fluid} />
                                <span>Javascript</span>
                            </div>
                        </div>

                        <div className="logos-grid__row">
                            <div className="logos-grid__item">
                                <Img alt="Logo React JS" fluid={data.react.childImageSharp.fluid} />
                                <span>React</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Android" fluid={data.android.childImageSharp.fluid} />
                                <span>Android</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo iOS" fluid={data.ios.childImageSharp.fluid} />
                                <span>iOS</span>
                            </div>
                            <div className="logos-grid__item">
                                <Img alt="Logo Digital Ocean" fluid={data.digitalocean.childImageSharp.fluid} />
                                <span>Digital Ocean</span>
                            </div>
                        </div>
                    </div>
                </div>
            </WillAnimate>
            <Cta image={data.olav.childImageSharp.fixed} />
        </section>
    </Layout>
);

export const query = graphql`
    query {
        keeping: file(relativePath: {eq: "portfolio/keeping/keeping-barend-header.jpg"}) {
            ...fullscreenHero
        }

        olav: file(relativePath: {eq: "people/olav_2_zoom.jpg"}) {
            ...inlineAvatarImage
        }

        keeping3d: file(relativePath: {eq: "portfolio/keeping/3d-text-nl.png"}) {
            ...fluid1600Width
        }
        keepingOverview: file(relativePath: {eq: "portfolio/keeping/keeping-overview.jpg"}) {
            ...fluid1200Width
        }
        keepingAndroid: file(relativePath: {eq: "portfolio/keeping/keeping-android.png"}) {
            ...fluid800Width
        }
        keepingTeams: file(relativePath: {eq: "portfolio/keeping/keeping-teams.jpg"}) {
            ...fluid800Width
        }
        keepingReports: file(relativePath: {eq: "portfolio/keeping/keeping-reports.png"}) {
            ...fluid800Width
        }
        keepingExtension: file(relativePath: {eq: "portfolio/keeping/keeping-extension.png"}) {
            ...fluid800Width
        }
        keepingIntegrations: file(relativePath: {eq: "portfolio/keeping/keeping-integrations.jpg"}) {
            ...fluid800Width
        }
        keepingTeam: file(relativePath: {eq: "portfolio/keeping/keeping-team.jpg"}) {
            ...fluid1200Width
        }
        keepingWebsite: file(relativePath: {eq: "portfolio/keeping/keeping-zzp.jpg"}) {
            ...fluid1200Width
        }

        android: file(relativePath: {eq: "logos/android.png"}) {
            ...logoImage
        }
        ios: file(relativePath: {eq: "logos/ios.png"}) {
            ...logoImage
        }
        laravel: file(relativePath: {eq: "logos/laravel.png"}) {
            ...logoImage
        }
        php: file(relativePath: {eq: "logos/php.png"}) {
            ...logoImage
        }
        mysql: file(relativePath: {eq: "logos/mysql.png"}) {
            ...logoImage
        }
        javascript: file(relativePath: {eq: "logos/javascript.png"}) {
            ...logoImage
        }
        react: file(relativePath: {eq: "logos/react.png"}) {
            ...logoImage
        }
        digitalocean: file(relativePath: {eq: "logos/digitalocean.png"}) {
            ...logoImage
        }

        asana: file(relativePath: {eq: "portfolio/keeping/integrations/asana.png"}) {
            ...logoImage
        }
        basecamp: file(relativePath: {eq: "portfolio/keeping/integrations/basecamp.png"}) {
            ...logoImage
        }
        eboekhouden: file(relativePath: {eq: "portfolio/keeping/integrations/eboekhouden.png"}) {
            ...logoImage
        }
        employes: file(relativePath: {eq: "portfolio/keeping/integrations/employes.png"}) {
            ...logoImage
        }
        exact: file(relativePath: {eq: "portfolio/keeping/integrations/exact.png"}) {
            ...logoImage
        }
        github: file(relativePath: {eq: "portfolio/keeping/integrations/github.png"}) {
            ...logoImage
        }
        jira: file(relativePath: {eq: "portfolio/keeping/integrations/jira.png"}) {
            ...logoImage
        }
        jortt: file(relativePath: {eq: "portfolio/keeping/integrations/jortt.png"}) {
            ...logoImage
        }
        moneybird: file(relativePath: {eq: "portfolio/keeping/integrations/moneybird.png"}) {
            ...logoImage
        }
        nmbrs: file(relativePath: {eq: "portfolio/keeping/integrations/nmbrs.png"}) {
            ...logoImage
        }
        random: file(relativePath: {eq: "portfolio/keeping/integrations/random.png"}) {
            ...logoImage
        }
        snelstart: file(relativePath: {eq: "portfolio/keeping/integrations/snelstart.png"}) {
            ...logoImage
        }
        todoist: file(relativePath: {eq: "portfolio/keeping/integrations/todoist.png"}) {
            ...logoImage
        }
        trello: file(relativePath: {eq: "portfolio/keeping/integrations/trello.png"}) {
            ...logoImage
        }
        twinfield: file(relativePath: {eq: "portfolio/keeping/integrations/twinfield.png"}) {
            ...logoImage
        }
    }
`;
